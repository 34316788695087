* {
  box-sizing: border-box;
}

$primary: #09152f;
$secondary: #193dcd;
h1 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  color: $primary;
  margin: 0;
  padding: 0;
}
h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  color: $primary;
}
h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: $primary;
}
p {
  font-family: 'Poppins';
  margin: 0;
  padding: 0;
}
