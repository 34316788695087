@import '../scss/bases/typographies.scss';
@import '../scss/bases/bases.scss';

:root {
  color-scheme: light;
}

body {
  background-color: #f5f8ff;
}

.primary-btn {
  display: flex;
  align-items: center;
  width: 168px;
  background-color: #193dcd;
  height: 48px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #f5f9ff;
  border: none;
  justify-content: space-around;
  border-radius: 10px;
  cursor: pointer;
}

.warning-btn {
  @extend .primary-btn;
  background-color: #ea5943;
}

.add-to-favorite {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 2rem;

  &-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
  }
}
.error-message {
  font-size: 12px;
  color: red;
}
main {
  margin-top: 100px;
}
.loading {
  font-size: 10px;
  color: #193dcd;
}

body::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #c6d3ff;
  border-radius: 10px;
}
