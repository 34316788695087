.app-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 50px;
  z-index: 99;
  background-color: #09152f;
  &_content {
    display: flex;
    flex-basis: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
    position: relative;
    .logo-container {
      position: absolute;
      top: 10px;
      left: 40px;

      img {
        height: 30px;
      }
    }
  }
  .goHomeBtn {
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    background-color: #f5f8ff;
    padding: 15px 25px;
    position: absolute;
    left: 250px;
    top: 7px;
  }
  .userInfo {
    position: relative;
    display: flex;
    padding: 0 10px;
    align-items: center;
    cursor: pointer;
    .user_name {
      color: #f5f9ff;
      margin-right: 10px;
    }
    .profil_picture {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #eef3ff;
      background-color: lightgray;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .dropdown-btn {
      margin-left: 10px;
    }
    .navigation-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 20px;
      top: 40px;
      right: 10px;
      width: 140px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #09152f;
      z-index: 11;
      .my-account {
        color: #f5f9ff;
        text-decoration: none;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 14px;
      }
      .logout {
        font-weight: bold;
        background-color: #193dcd;
        font-weight: bold;
        color: #f5f9ff;
        padding: 10px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }
    }
  }
}
